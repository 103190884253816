<template>
    <div :id="chartID" class="w-100 h-100"></div>
</template>

<script setup lang="ts">
import { ref, watch, nextTick } from "vue";
import { getOrCreateProcedure } from 'o365-modules';
import { importUtils } from 'o365-utils';
import { buildUrl } from 'status.vue.components.layoutHelpers.LayoutUrlFilterBuilder.ts'

const props = defineProps({
    chartConfig: {
        type: String,
        required: true,
        default: null
    },
    procedure: {
        type: String,
        required: true,
        default: null
    },
    procedureParams: {
        type: Object,
        required: false,
        default: null
    },
    dashboardUrl: {
        type: String,
        required: false,
        default: null
    },
    colorPalette: {
        type: String,
        required: false,
        default: null
    }
});

const emit = defineEmits(["loaded", "error"]);

const moduleLoaded = ref(false);

var loadDynamicChartProc = {};
const chartID = ref("chart_" + crypto.randomUUID());
let chart = null;

let HIGHCHARTS_INIT_PROMISE: Promise<void> | null = null;
loadHighCharts().then(() => {
    moduleLoaded.value = true;
    return nextTick();
}).then(() => {
    load();
}).catch(ex => {
    console.log(ex);
});

function load() {
    if (!props.procedure) return
    loadDynamicChartProc = getOrCreateProcedure({ id: props.procedure, procedureName: props.procedure, useAlert: false, useGroupedRequests: false });

    loadDynamicChartProc.execute(getProcedureParams()).then(async (response) => {
        try {
            let data;
            if (response.hasOwnProperty("Table")) {
                data = response.Table;
            } else {
                data = response;
            }

            const options = JSON.parse(props.chartConfig);
            const categoryColumn = options.categoryColumn.name;
            const isDistinct = options.categoryColumn.distinct;

            delete options.categoryColumn;
            delete options.seriesColumns;

            if (options.xAxis == null) { options.xAxis = {} };

            // If data will be datetime it must be converted to timestamp for format to be working.
            if (options.xAxis.type === 'datetime') {
                data.forEach(item => {
                    let timeStamp = new Date(item[categoryColumn]).getTime();
                    item[categoryColumn] = isNaN(timeStamp) ? 0 : timeStamp;
                })
            };

            handleSeriesColors(options.series);
            handleDataSorting(options.xAxis, data, categoryColumn);

            if (options.chart.type === 'pie') {
                options.series[0].data ||= [];

                for (let i = 0; i < data.length; i++) {
                    options.series[0].data.push({
                        name: data[i][options.series[0].serieColumn],
                        y: data[i][options.series[0].valueColumn]
                    });
                }

                options.series[0].type = 'pie';
            } else {
                if (isDistinct) {
                    // Distinct can only use columnColor if provided
                    delete options.series[0].color;

                    let newSeriesNames: Array<{ name: string; color?: string | null }> = [];
                    let categoryList: Array<string> = [];

                    data.forEach((row) => {
                        let seriesName: string = row[options.series[0].serieColumn];
                        let seriesColor: string | null = null;

                        if (!newSeriesNames.some(tuple => tuple.name === seriesName)) {
                            if (options.series[0].useDefaultColor === false && options.series[0].colorColumn) {
                                seriesColor = row[options.series[0].colorColumn] || null;
                            }

                            newSeriesNames.push({ name: seriesName, color: seriesColor });
                        }

                        let categoryName: string = row[categoryColumn];
                        if (!categoryList.includes(categoryName)) {
                            categoryList.push(categoryName);
                        }
                    });

                    let newSeries: Array<Object> = [];

                    // Currently only first series is supported when distinct is used.
                    newSeriesNames.forEach((seriesName) => {
                        let serieObject: Object = {
                            ...options.series[0],
                            name: seriesName.name,
                            data: []
                        }

                        if (options.series[0].useDefaultColor === false && seriesName.color) {
                            serieObject.color = seriesName.color;
                        }

                        categoryList.forEach((category) => {
                            let dataObjs = data.filter(row => row[categoryColumn] == category && row[options.series[0].serieColumn] === seriesName.name);
                            if (dataObjs.length > 0) {
                                let totalValue = dataObjs.reduce((sum, dataObj) => {
                                    let value = dataObj[options.series[0].valueColumn];

                                    return sum + value;
                                }, 0);

                                if (options.series[0].dataType === 'percent') {
                                    totalValue = totalValue * 100;
                                }

                                serieObject.data.push(totalValue);
                            }
                            else {
                                serieObject.data.push(null);
                            }
                        });

                        if (serieObject.data && (serieObject.type == 'waterfall' || options.chart.type == 'waterfall') && serieObject.showWaterfallTotal) {
                            serieObject.data.push({
                                isSum: true
                            });
                            categoryList.push('Total');
                        }

                        newSeries.push(serieObject)
                    });

                    options.series = newSeries;
                    options.xAxis.categories = categoryList;
                } else {
                    options.series.forEach(seriesColumn => {
                        if (seriesColumn.valueColumn) {
                            seriesColumn.data = data.map(item => item[seriesColumn.valueColumn]);
                        }

                        if (seriesColumn.dataType === 'percent') {
                            seriesColumn.data = seriesColumn.data.map(value => value * 100);
                        }

                        if ((seriesColumn.type == 'waterfall' || options.chart.type == 'waterfall') && seriesColumn.showWaterfallTotal) {
                            seriesColumn.data.push({
                                isSum: true
                            });
                        }

                        if (seriesColumn.animation == null) {
                            seriesColumn.animation = {
                                duration: 500,
                                easing: 'easeOutBounce'
                            };
                        }
                    });
                    options.xAxis.categories = data.map(item => item[categoryColumn]);

                    const hasWaterfallSum = options.series?.some(series => series?.showWaterfallTotal && (series?.type == 'waterfall' || options.chart.type == 'waterfall'));
                    if (hasWaterfallSum) {
                        options.xAxis.categories.push('Total');
                    }
                }
            }

            handleColorPalette(options);
            handleExporting(options);

            chart = Highcharts.chart(chartID.value, options);

            const hasUrlObjectEnabled = options.series?.some(series => series?.urlObject?.enabled);
            if (hasUrlObjectEnabled) {
                chart.update({ plotOptions: { series: { point: { events: { click: function (value) { return getItemUrl(value, data, options, categoryColumn, isDistinct) } } } } } })
            }

            emit("loaded");
        } catch (error) {
            console.log(error);
            emitError(error);
        }
    }).catch((error: any) => {
        console.log(error);
        emitError(error);
    });

}

function emitError(error: any) {
    if (error.message && error.message.includes("@")) {
        let field = error.message.split("@")[1].split("'")[0]; // get required field from error message
        emit("error", error.message, field);
    } else {
        emit("error", error.message, null);
    }
}

async function getItemUrl(value, data, options, categoryColumn, isDistinct: boolean) {
    const series = options.series[value.point.series.index];
    if (!series.urlObject.enabled) {
        return;
    }

    let foundItem;
    if (isDistinct) {
        foundItem = data.find((obj) => obj[categoryColumn] === value.point.category && obj[series.serieColumn] === value.point.series.name);
    } else {
        foundItem = data.find((obj) => obj[categoryColumn] === value.point.category);
    }

    if (!foundItem || !series.urlObject.application || !series.urlObject.gridDataObject) {
        return;
    }
    
    let url = await buildUrl(series.urlObject, foundItem);

    window.open(url, "_blank");
}

function getProcedureParams() {
    if (props.procedureParams) {
        let cleanedUpObj = {};
        Object.entries(props.procedureParams).forEach(([key, value]) => {
            if (value) {
                cleanedUpObj[key] = isNaN(value) ? value : parseFloat(value);
            } else {
                cleanedUpObj[key] = null;

            }
        });
        return cleanedUpObj
    } else if (props.dashboardUrl) {
        let procParams = {};
        new URLSearchParams(props.dashboardUrl.split("?")[1]).forEach((value, key) => {
            if (key == "name") return; // to not pass the chart name as param
            procParams[key] = value;
        });
        return procParams
    } else {
        return {}
    }
}

function handleSeriesColors(series) {
    series.forEach(seriesColumn => {
        if (seriesColumn.useDefaultColor) {
            delete seriesColumn.color;
        }
    });
}

function handleColorPalette(options) {
    if (props.colorPalette) {
        options.colors = props.colorPalette.split(",");
    }
}

function handleExporting(options) {
    // Set no matter the user input
    if (!options.exporting) {
        options.exporting = {
            enabled: false,
            fallbackToExportServer: false
        };
        return;
    }

    // Set no matter the user input
    options.exporting.fallbackToExportServer = false;

    if (!options.exporting?.enabled) {
        return;
    }

    if (!options.exporting.buttons) {
        options.exporting.buttons = {
            contextButton: {
                menuItems: [
                    "viewFullscreen",
                    "printChart",
                    "downloadPNG",
                    "downloadJPEG",
                    "downloadPDF"
                ]
            }
        };
    }


}

function handleDataSorting(xAxis, data, categoryColumn) {
    if (!xAxis.sortOrder) {
        return;
    }

    if (xAxis.sortOrder === 'asc') {
        data.sort((a, b) => (a[categoryColumn] > b[categoryColumn] ? 1 : -1));
    } else if (xAxis.sortOrder === 'desc') {
        data.sort((a, b) => (a[categoryColumn] < b[categoryColumn] ? 1 : -1));
    }
}

watch(() => props.procedureParams, () => {
    if (chartID.value && moduleLoaded.value) {
        load();
    }
}, { deep: true })


async function loadHighCharts() {
    if (HIGHCHARTS_INIT_PROMISE == null) {
        let resolve = () => { };
        let reject = (pEx: Error) => { };
        HIGHCHARTS_INIT_PROMISE = new Promise<void>((res, rej) => {
            resolve = res;
            reject = rej;
        });

        importUtils.loadScript('highcharts').then(() => {
            return importUtils.loadScript('highcharts/code/modules/exporting.js');
        }).then(() => {
            return importUtils.loadScript('highcharts/code/modules/offline-exporting.js');
        }).then(() => {
            return importUtils.loadScript('highcharts/code/modules/drilldown.js');
        }).then(() => {
            return importUtils.loadScript('highcharts/code/modules/export-data.js');
        }).then(() => {
            return importUtils.loadScript('highcharts/code/highcharts-more.js');
        }).then(() => {
            resolve();
        }).catch((ex) => {
            reject(ex);
        });
        return HIGHCHARTS_INIT_PROMISE;
    } else {
        return HIGHCHARTS_INIT_PROMISE;
    }
}
</script>